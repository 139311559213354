import React from "react";
import { graphql } from "gatsby";
import { MainLayout } from "../layouts";
import { PodcastHero, EpisodeListItems, ListenOn } from "../components";
import { Helmet } from "react-helmet";
import ogImg from "../images/metadata.png";

const PodcastPage = ({ data }) => {
  // content from site metadata
  const { siteUrl } = data.site.siteMetadata;

  return (
    <MainLayout>
      <Helmet>
        <title> The Yes-code Podcast | yescode.org</title>
        <meta
          name="description"
          content="The Yes-code Podcast explores topics that are painful in a developer’s daily practice – but don’t have to be. We speak with experts whose tools solve complex problems, so you don’t have to. Listen to get a crisp understanding of persistent, meaty problem areas, and learn about tools that make building software feel like magic."
        />
        <meta name="keywords" content="code, developers, manifesto" />
        <meta property="og:url" content="https://www.yescode.org/podcast" />
        <meta property="og:title" content="Yes-code" />
        <meta
          property="og:description"
          content="The Yes-code Podcast explores topics that are painful in a developer’s daily practice – but don’t have to be. We speak with experts whose tools solve complex problems, so you don’t have to. Listen to get a crisp understanding of persistent, meaty problem areas, and learn about tools that make building software feel like magic."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${ogImg}`} />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="608" />
        <meta property="og:image:alt" content="Yes-code " />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="The Yes-code Podcast | yescode.org"
        />
        <meta
          name="twitter:description"
          content="The Yes-code Podcast explores topics that are painful in a developer’s daily practice – but don’t have to be. We speak with experts whose tools solve complex problems, so you don’t have to. Listen to get a crisp understanding of persistent, meaty problem areas, and learn about tools that make building software feel like magic."
        />
        <meta name="twitter:image" content={`${siteUrl}${ogImg}`} />
        <link rel="icon" href="/" />
        <html lang="en" />
      </Helmet>
      <PodcastHero />
      <ListenOn />
      <EpisodeListItems data={data} />
    </MainLayout>
  );
};

export default PodcastPage;

// query for page content
export const query = graphql`
  query PodcastPage {
    markdown: allMarkdownRemark {
      nodes {
        frontmatter {
          buzzsproutSlug
          slug
        }
      }
    }
    buzzpodcast: allPodcastEpisodeControlRemoto {
      nodes {
        title
        description
        id
        artwork_url
        published_at(formatString: "M/D/Y")
        buzzsproutSlug: slug
        episode_number
        hide: private
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
